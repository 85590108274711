import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import LocaleContext from '$components/structure/Layout/LocaleContext'
import config from '$src/config'

const Meta = ({
  title,
  titleReplace,
  titleDivider,
  description,
  keywords,
  ogTwitter,
  ogType,
  ogLocale,
  ogImage,
  ogImageHeight,
  ogImageWidth,
  polyfills,
}) => {
  const { locale } = React.useContext(LocaleContext)
  const translations = config.i18n[locale]

  const constructedTitle = titleReplace
    ? title
    : `${title} ${titleDivider} ${translations.defaultSiteTitle}`

  const fullOgImageUrl = translations.baseUrl + ogImage

  // prettier-ignore
  const constructedPolyfillsUrl = polyfills
    ?`https://polyfill.io/v3/polyfill.min.js?flags=gated&features=${polyfills.join('%2C')}`
    : null

  return (
    // prettier-ignore
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
    >
      {title && <title>{constructedTitle}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}

      {title && <meta property="og:title" content={constructedTitle} />}
      {description && <meta property="og:description" content={description} />}
      {ogTwitter && <meta property="twitter:site" content={ogTwitter} />}
      {ogType && <meta property="og:type" content={ogType} />}
      {ogLocale && <meta property="og:locale" content={ogLocale} />}
      {ogImage && <meta property="og:image" content={fullOgImageUrl} />}
      {ogImageHeight && <meta property="og:image:height" content={ogImageHeight} />}
      {ogImageWidth && <meta property="og:image:width" content={ogImageWidth} />}
      {polyfills && <script src={constructedPolyfillsUrl} />}
    </Helmet>
  )
}

Meta.propTypes = {
  title: PropTypes.string,
  titleReplace: PropTypes.bool,
  titleDivider: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  ogTwitter: PropTypes.string,
  ogType: PropTypes.oneOf(['article', 'book', 'profile', 'website']),
  ogLocale: PropTypes.string,
  ogImage: PropTypes.string,
  ogImageHeight: PropTypes.string,
  ogImageWidth: PropTypes.string,
  polyfills: PropTypes.arrayOf(PropTypes.string),
}

Meta.defaultProps = {
  titleDivider: '|',
}

export default Meta
