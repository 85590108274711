import React from 'react'

import CookieConsent from '$components/structure/CookieConsent'
import Footer from '$components/structure/Footer'
import Header from '$components/structure/Header'
import LocaleContext from './LocaleContext'
import Meta from '$components/common/Meta'
import Navigation from '$components/structure/Navigation'
import Wrapper from '$components/structure/Wrapper'

import config from '$src/config'
import imgOgImage from '$assets/opengraph.jpg'

// prettier-ignore
const defaultLocale = Object.entries(config.i18n).find((lang) => lang[1].default)[0]

const Layout = ({ children, pageContext: { locale } }) => {
  const currentLocale = locale || defaultLocale

  const {
    defaultSiteTitle,
    defaultSiteDescription,
    ogTwitter,
    ogLocale,
  } = config.i18n[currentLocale]

  return (
    <LocaleContext.Provider value={{ locale: currentLocale }}>
      <CookieConsent />

      <Meta
        title={defaultSiteTitle}
        titleReplace
        description={defaultSiteDescription}
        ogTwitter={ogTwitter}
        ogType="website"
        ogLocale={ogLocale}
        ogImage={imgOgImage}
        ogImageHeight="1200"
        ogImageWidth="1200"
        polyfills={['default', 'Symbol']}
      />

      <Wrapper>
        <Header />
        <Navigation />
        <main>{children}</main>
        <Footer />
      </Wrapper>
    </LocaleContext.Provider>
  )
}

export default Layout
