import React from 'react'

import LocalizedLink from '$components/common/LocalizedLink'
import translator from '$utils/translator'

import imgCta from './cta.svg'
import imgLogo from './logo.svg'

import styles from './Header.module.scss'

const Header = () => {
  const { telComputer, telHuman } = translator()

  return (
    <header className={styles.header}>
      <div className={styles.cta}>
        <a href={`tel:${telComputer}`}>
          <img src={imgCta} alt={telHuman} />
        </a>
      </div>

      <LocalizedLink to="/" className={styles.logo}>
        <img src={imgLogo} alt="logo" />
      </LocalizedLink>
    </header>
  )
}

export default Header
