import React from 'react'
import { Link } from 'gatsby'

import LocaleContext from '$components/structure/Layout/LocaleContext'
import config from '$src/config'

const LocalizedLink = ({ to, ...props }) => {
  const { locale } = React.useContext(LocaleContext)
  const isIndex = to === '/'
  const currentLocale = config.i18n[locale]

  const path = currentLocale.default
    ? to
    : `${currentLocale.path}${isIndex ? '' : `/${to}`}`

  // Fix slashes
  const fixedPath = `/${path}`.split(/\\\\|\\|\/\/|\//).join('/')

  return <Link {...props} to={fixedPath} />
}

export default LocalizedLink
