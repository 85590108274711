import React, { useState, useEffect } from 'react'

import cookies from '$utils/cookies'
import translator from '$utils/translator'
import styles from './CookieConsent.module.scss'

const CookieConsent = () => {
  const { misc_cookies } = translator()

  const [consent, setConsent] = useState(true)
  useEffect(() => {
    if (!cookies.get('COOKIECONSENT')) {
      setConsent(false)
    }
  }, [consent])

  const _handleClick = () => {
    setConsent(true)

    const date = new Date()
    date.setFullYear(date.getFullYear() + 10)

    cookies.set('COOKIECONSENT', 'true', {
      path: '/',
      expires: date,
    })
  }

  if (!consent) {
    return (
      <button
        className={[
          styles.cookieConsent,
          consent ? styles.consented : null,
        ].join(' ')}
        onClick={() => _handleClick()}
      >
        <span className={styles.innerText}>{misc_cookies}</span>

        <div className={styles.innerButton}>×</div>
      </button>
    )
  }

  return null
}

CookieConsent.propTypes = {}

CookieConsent.defaultProps = {}

export default CookieConsent
