import React from 'react'

import LocalizedLink from '$components/common/LocalizedLink'
import translator from '$utils/translator'

import imgCta from './cta.svg'
import imgLogo from './logo.svg'

import styles from './Footer.module.scss'

const Footer = () => {
  const { subline, telComputer, telHuman, telHumanFull, email } = translator()

  return (
    <footer className={styles.footer}>
      <div className="wrapper-m">
        <a href={`tel:${telComputer}`} className={styles.cta}>
          <img src={imgCta} alt={telHuman} />
        </a>

        <div className={styles.content}>
          <div className={styles.logo}>
            <LocalizedLink to="/">
              <img src={imgLogo} alt="logo" />
            </LocalizedLink>
          </div>

          <div>{subline}</div>

          <div>
            <a href={`tel:${telComputer}`} className={styles.telLink}>
              {telHumanFull}
            </a>
          </div>

          <div>
            <a
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
