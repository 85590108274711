module.exports = {
  title: 'WillemUitvaart',
  trackingId: 'UA-139201845-1',
  i18n: {
    nl: {
      default: true,
      name: 'Nederlands',
      path: 'nl',
      locale: 'nl-NL',
      dateFormat: 'DD-MM-YYYY',
      siteLanguage: 'nl',
      ogLocale: 'nl_NL',
      baseUrl: 'https://www.willemuitvaart.nl',
      defaultSiteTitle: 'WillemUitvaart',
      defaultSiteDescription: 'Persoonlijke en transparante uitvaartverzorger in de regio \'s-Hertogenbosch. WillemUitvaart luistert, ondersteunt en stelt gerust.',
    },
  }
}
