import React, { useState } from 'react'

import Icon from '$components/common/Icon'
import LocalizedLink from '$components/common/LocalizedLink'

import translator from '$utils/translator'

import imgLogoVerticalwhite from '$assets/logo_vertical_white.svg'
import styles from './Navigation.module.scss'

// prettier-ignore
const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false)
  const { navigation } = translator()

  const classList = {
    toggle: [
      styles.toggle,
      navOpen ? styles.open : null
    ].join(' '),
    navigation: [
      styles.navigation,
      navOpen ? styles.open : null
    ].join(' ')
  }

  const changePage = () => {
    setNavOpen(false)
    document.body.scrollTo(0, 0)
  }

  return (
    <>
      <button className={classList.toggle} onClick={() => setNavOpen(!navOpen)} aria-label="menu open/close">
        <Icon name={navOpen ? 'close' : 'nav'} size="40" />
      </button>
      <div className={classList.navigation}>

        <div className="wrapper-m">
          <LocalizedLink to="/" className={styles.logo} onClick={() => changePage()}>
            <img src={imgLogoVerticalwhite} alt="logo" />
          </LocalizedLink>

          <nav>
          {navigation.map((item) => (
            <React.Fragment key={item.path}>
              <LocalizedLink to={item.path} className={styles.link} activeClassName={styles.linkActive} onClick={() => changePage()}>
                <span className={styles.linkText}>{item.text}</span>
                <span className={styles.activeLinkText}>{item.textActive}</span>
              </LocalizedLink>
              <span className={styles.linkDivider}>|</span>
            </React.Fragment>
          ))}
          </nav>
        </div>
      </div>
    </>
  )
}

export default Navigation
