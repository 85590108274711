import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LocaleContext from '$components/structure/Layout/LocaleContext'
import '$src/translations/translationKeys'

const translator = () => {
  const { locale } = React.useContext(LocaleContext)
  const { rawData } = useStaticQuery(query)

  const simplifiedGraphQLRes = rawData.edges.map((item) => ({
    name: item.node.name,
    translations: item.node.translations,
  }))

  // Only return translations for the current locale
  // prettier-ignore
  const { translations } = simplifiedGraphQLRes.filter((lang) => lang.name === locale)[0]

  return translations
}

export default translator

const query = graphql`
  query translator {
    rawData: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            ...translationKeys
          }
        }
      }
    }
  }
`
